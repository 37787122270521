<template>
  <ol reversed :style="{'counter-reset': `li ${editions.length + 1}`}">
    <li v-for="(d, i) in editions" :key="i + 1">
      <a :href="d.link" target="_blank">{{ d.name }}</a>
      <span class="mon-period">{{ d.date }}</span>
    </li>
  </ol>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: "Monitorings",
  data() {
    return {
      editions: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const that = this;
      d3.tsv('monitorings.tsv').then(function(data) {
        that.editions = data;
      })
    }
  }
}
</script>