<template>
<main>
  <div class="header-side">
    <p>Seelct dates</p>
  </div>
  <header-time :days="days"/>
  <div class="header-side"></div>
  
  <article id="subtopic-view">

    <svg v-if="!isMobile" id="subtitle-lines" viewBox="0 0 350 30" preserveAspectRatio="none">
      <path d="M0 6 L135 6 L135 16 L250 16 L250 26 L 280 26"
      vector-effect="non-scaling-stroke"/>
    </svg>

    <nav id="metatopic-menu">
      <h4>
        Topic
        <router-link v-if="isMobile" :to="{path: '/', query: $route.query}" 
          class="back">Home</router-link>
      </h4>
      <h2>{{ meta2name[sub_slug] }}</h2>
      <p class="legend">The bar height shows % of the topic among all the news of the day in a group of sites</p>
      <figure id="metatopic-chart">
        <chart :daily="metatopic"
          :slug="`mt_${sub_slug}`"
          :days="days"
          :axis="true"
          :annotate="true"
          :tooltip="!isTouch"
          :maxY="maxY"
        />
      </figure>

      <p id="for-site-menu" v-if="!isMobile">Types of sites:</p>
      <sitetype :no_toggle="!isMobile"/>
      <router-link v-if="!isMobile" :to="{path: '/', query: $route.query}"
        class="back">Home</router-link>
      
    </nav>

    <section id="subtopics">
      <h4>Subtopics</h4>
      <div id="loading" v-if="loadingTopics">
        <img src="img/loading_icon.png"/>
      </div>
      <transition-group name="subtopics" tag="div">
        <figure v-for="(v, k, i) in subtopics"
          :key="i+1"
          :class="{'subtopic-chart': true, active: k === sub_topic}">
          <!-- <div @click="isTouch || isMobile ? selectSubTopic(k) : null"
            @mouseover="isTouch || isMobile ? null : selectSubTopicWait(k)"> -->
            <div @click="isTouch || isMobile 
              ? selectSubTopic(k) 
              : selectSubTopicWait(k)">
            <h3>
              <a v-if="k === sub_topic"
                role="button"
                title="Download infographics"
                @click="downloadedPng = k">
                <img src="img/download.svg">
              </a>{{ cl2name[k] }} 
            </h3>
            <chart :daily="v"
              :slug="k"
              :days="days"
              :tooltip="k === sub_topic && !isTouch"
              :scaled="k === sub_topic"
              :maxY="maxY"
              v-on:scroll-headlines="scrollHeadlines"/>
            <getPng v-if="downloadedPng === k"
              :daily="v"
              :slug="k"
              :days="days"
              :scaled="k === sub_topic"
              :title="cl2name[k]"
              :maxY="maxY"
              v-on:pngready="downloadedPng = null"/>
          </div>

          <transition name="dropdown" v-if="isMobile & k === sub_topic">
            <headlines :subtopic="sub_topic"
              :sitetypes="sitetypes"
              :from="from"
              :to="to"
              :isMobile="isMobile"/>
          </transition>
        </figure>
      </transition-group>
    </section>

    <div id="headline-feed-container" v-if="!isMobile"> 
      <h4>Headlines</h4>
      <headlines :subtopic="sub_topic"
        :sitetypes="sitetypes"
        :from="from"
        :to="to"
        :isMobile="isMobile"
        :scrollToDate="scrollToDate"/>
    </div>
  </article>

  <slot></slot>
</main>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Subtopic',
  props: {
    sub_slug: String,
    days: Array,
    sub_topic: String,
    cl2name: Object,
    meta2name: Object,
  },
  data() {
    return {
      subtopics: {},
      publicPath: process.env.BASE_URL,
      showOtherTopics: false,
      metatopic: [],
      loadingTopics: true,
      downloadedPng: null,
      pngURI: '#',
      scrollToDate: null,
    }
  },

  watch: {
    sub_slug: function(new_slug) {
      this.subtopics = {};
      this.loadingTopics = true;
      this.loadData(new_slug);
    },
    '$route.query.sitetypes': 'checkRouteUpd',
  },


  computed: {
    isMobile: function() {
      return window.innerWidth < 768;
    },

    isTouch: function() {
      if ('ontouchstart' in window || window.DocumentTouch ) {
        return true;
      }
      const q = "(touch-enabled),(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-touch-enabled),(heartz)"

      return window.matchMedia(q).matches;
    },

    sitetypes: function() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['uk_man', 'ru_for_uk_man'];
    },

    maxY: function() {
      const that = this;
      const m = Math.max(...that.metatopic.map(d => d.perc)) * 100
      return Math.floor(m / 5) / 20;
    },

    from: function() {
      const qd = this.$route.query.from;
      return qd 
        ? new Date(qd)
        : this.pdate(this.days[this.days.length - 151]) 
    },

    to: function() {
      const qd = this.$route.query.to;
      return qd 
        ? new Date(qd)
        : this.pdate(this.days[this.days.length - 1]) 
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData(subslug=this.sub_slug) {
      const that = this;
      Promise.all(
        [...that.sitetypes.map(n => d3.json(`${that.publicPath}data/${n}/${subslug}.json`))]
      ).then(function(files) {
        that.metatopic = files[0].metatopic
        delete files[0]['metatopic']
        that.subtopics = files[0];
        if (files.length === 2) {
          that.metatopic = that.metatopic.concat(files[1].metatopic)
          delete files[1]['metatopic']
          Object.entries(files[1]).map(function([k, v]) {
            that.subtopics[k] = that.subtopics[k] ? that.subtopics[k].concat(v) : v
          });
        }
        that.loadingTopics = false;
      })
    },

    scrollMethodology() {
      document.querySelector('#methodology').scrollIntoView({behavior: 'smooth'});
    },

    formatTime(t) {
      return d3.timeFormat('%d.%m.%y')(new Date(t));
    },

    substractWeek(date) {
      date = new Date(date);
      date.setDate(date.getDate() - 7);
      return date;
    },

    selectSubTopic(subt) {
      this.scrollToDate = null;
      if (this.sub_topic === subt) {
        this.$router.replace({
          path: '/' + this.sub_slug,
          query: this.$route.query,
          props: {sub_slug: this.sub_slug, },
        });
      } else {
        this.$router.replace({
          path: `/${this.sub_slug}/${subt}`,
          query: this.$route.query,
        })
      }
    },
    
    selectSubTopicWait(subt) {
      if (subt === this.sub_topic) return;
      this.selectSubTopic(subt);
    },

    checkRouteUpd() {
      this.subtopics = {};
      this.metatopic = [];
      this.loadingTopics = true;
      this.loadData();
    },

    pdate(d) {
      return d3.timeParse('%d.%m.%y')(d)
    },

    fdate(d) {
      return d3.timeFormat('%d.%m.%y')(d)
    },

    scrollHeadlines(e) {
      this.scrollToDate = e;
    }
  },
}
</script>

<style lang="scss">
@import './assets/scss/subtopic.scss';
</style>


