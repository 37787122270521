import 'es6-promise/auto'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueTippy, { TippyComponent } from "vue-tippy";

import Home from './Home.vue'
import Chart from './Chart.vue'
import Header from './Header.vue'
import Subtopic from './Subtopic.vue'
import Disclaimer from './Disclaimer.vue'
import GetPng from './GetPng.vue'
import Sitetype from './Sitetype.vue'
import Headlines from './Headlines.vue'
import Monitorings from './Monitorings.vue'

import days from '@/assets/data/days.json';
import cl2name from '@/assets/data/clusters.json';
import meta2name from '@/assets/data/metatopics.json';

// eslint-disable-next-line
import font from '@/assets/scss/fonts.scss';
// console.log(font)

Vue.config.productionTip = true
Vue.use(VueRouter)

Vue.component('chart', Chart)
Vue.component('header-time', Header)
Vue.component('subtopic', Subtopic)
Vue.component('disclaimer', Disclaimer)
Vue.component('getPng', GetPng)
Vue.component('sitetype', Sitetype)
Vue.component('headlines', Headlines)
Vue.component('monitorings', Monitorings)
Vue.component('tippy', TippyComponent)


Vue.use(VueTippy, {
  directive: "tippy",
  flipDuration: 0,
});

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: {days: days, meta2name: meta2name},
    },
    {
      path: '/:sub_slug',
      component: Subtopic,
      props: (route) => ({
        sub_slug: route.path.replace('/', ''),
        cl2name: cl2name,
        days: days,
        meta2name: meta2name,
      }),
    },
    {
      path: '/:sub_slug/:sub_topic',
      component: Subtopic,
      props: (route) => ({
        sub_slug: route.path.match(/^\/([a-z\-0-9_]+)/)[1],
        sub_topic: route.path.match(/\/([a-z\-0-9_]+)\/?$/)[1],
        days: days,
        cl2name: cl2name,
        meta2name: meta2name,
      }),
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return (from.path === '/' && to.path !== '/')
      || (from.path !== '/' && to.path === '/')
      || (from.params.sub_topic && !to.params.sub_topic)
      ? { selector: '#time-selector'}
      : savedPosition;
  },
})

new Vue({
  router,
}).$mount('#vue-mount')

document.querySelectorAll('header #menu-links a.menu-link, #disclaimer .link, #hline-disclaimer .link')
.forEach(function(el) {
  el.addEventListener("click", function() {
    document.querySelector(el.getAttribute('to'))
      .scrollIntoView({behavior: 'smooth'});
  })
})

