<template>
<section id="headline-feed" v-if="!isMobile">
  <p v-if="!subtopic" class="no-subtopic">
    Select a topic to see headlines
  </p>
  <transition-group v-if="compHeadlines.length"
    name="headlines" tag="div" id="headlines">
    <p key="-100" id="hline-disclaimer">Topics are detected by a machine learning algorithm, so a small share of news pieces can be in topic by mistake.<br>You can find more in the <a to="#methodology" class="link" role="button" @click="scrollMethodology">methodology</a></p>
    <div v-for="d in compHeadlines"
      :key="d.news_id"
      :data-date="d.date"
      ref="headlines">
      <p class="title">{{d.title}}</p>
      <p class="title-info">
        <span class="url_domain" :style="{color: colors[d.sitetype]}">
          {{ d.site }}
        </span>
        <span class="published"> | {{ fdate(d.date) }}</span>
      </p>
    </div>
  </transition-group>
</section>
<div v-else id="headline-feed-mob">
  <transition-group v-if="compHeadlines"
    name="headlines" tag="div" id="headlines">
    <p key="-100" id="hline-disclaimer">Topics are detected by a machine learning algorithm, so a small share of news pieces can be in topic by mistake.<br>You can find more in the <a to="#methodology" class="link" role="button" @click="scrollMethodology">methodology</a></p>
    <div v-for="d in compHeadlines" :key="d.news_id">
      <p class="title">{{d.title}}</p>
      <p class="title-info">
        <span class="url_domain" :style="{color: colors[d.sitetype]}">{{ d.site }}</span>
        <span class="published"> | {{ fdate(d.date) }}</span>
      </p>
    </div>
  </transition-group>
</div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Headlines',
  props: {
    subtopic: String,
    sitetypes: Array,
    from: Date,
    to: Date,
    isMobile: Boolean,
    scrollToDate: {default: null, type: String},
  },
  data() {
    return {
      headlines: [],
      publicPath: process.env.BASE_URL,
      colors: {
        uk_man: '#ff007d',
        ru_for_uk_man: '#8c0061',
        reg: '#f967c8',
        uk: '#000000',
        ru: '#7d7d7d',
	tg: '#8d8d8d',
      },
    };
  },

  created() {
    if (this.subtopic) {
      this.loadData()
    }
  },

  beforeDestroy() {
    this.headlines = [];
  },

  computed: {
    compHeadlines: function() {
      const that = this;
      return that.headlines.filter(function(d) {
        return d.date <= that.to & d.date >= that.from;
      })
    },
  },

  methods: {
    scrollMethodology() {
      document.querySelector('#methodology').scrollIntoView({behavior: 'smooth'});
    },

    loadData() {
      const that = this;
      Promise.all(
        [...that.sitetypes.map(
          n => d3.json(`${that.publicPath}data/${n}/headlines/${that.subtopic}.json`)
        )]
      ).then(function(files) {
        that.headlines = files[0].concat(...files.slice(1)
          ).map(function(d) {
            d.date = that.dparseISO(d.date)
            return d;
          })
          .sort(function(a, b) {
            return a.date > b.date ? -1 : 1;
          })
      });
    },

    dparseISO(d) {
      return new Date(d);
    },

    fdate(d) {
      return d3.timeFormat('%d.%m.%y')(this.dparseISO(d));
    },

    scrollHeadlines(dNew) {
      if (!dNew) {
        this.$el.scrollTop = 0
        return
      }
      const dt = d3.timeParse('%d.%m.%y')(dNew)
      const matchHeadline = this.$refs.headlines
        .find(d => new Date(d.getAttribute('data-date')) <= dt)
      this.$el.scrollTop = matchHeadline.offsetTop - 640
    }
  },

  watch: {
    'subslug': 'loadData',
    'sitetypes': 'loadData',
    'scrollToDate': 'scrollHeadlines',
  },
  
}
</script>
