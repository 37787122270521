var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"png"},[_c('figure',[_c('svg',{ref:"svg",style:({background: 'white'}),attrs:{"width":_vm.svgW,"height":_vm.svgH}},[_c('g',{attrs:{"transform":"translate(75 150)"}},[_c('g',{staticClass:"y-axis"},[_c('line',{style:({stroke: '#353535', 'stroke-width': '0.5px'}),attrs:{"x1":"-5","x2":"-5","y1":"0","y2":_vm.gH}}),_c('line',{style:({stroke: '#353535'}),attrs:{"x1":"-8","x2":"-2","y1":_vm.gH/2,"y2":_vm.gH/2}}),_c('text',{staticClass:"lab-zero",style:({'text-anchor': 'end', 'dominant-baseline': 'middle', 'font-size': '12px', 'font-family': 'Clear Sans',}),attrs:{"x":"-13","y":_vm.gH/2}},[_vm._v("0")]),_c('text',{staticClass:"lab-top",style:({'text-anchor': 'end', 'font-size': '12px', 'font-family': 'Clear Sans', 'dominant-baseline': 'middle'}),attrs:{"x":"-13","y":"0"}},[_vm._v(" "+_vm._s(((_vm.scaleY.domain()[1] * 100) + "%"))+" ")]),_c('text',{staticClass:"lab-bottom",style:({'text-anchor': 'end', 'font-size': '12px', 'font-family': 'Clear Sans', 'dominant-baseline': 'middle'}),attrs:{"x":"-13","y":_vm.gH}},[_vm._v(" "+_vm._s(((_vm.scaleY.domain()[1] * 100) + "%"))+" ")])]),_c('line',{style:({stroke: '#7c7c7c'}),attrs:{"x1":"0","x2":_vm.gW,"y1":_vm.gH/2,"y2":_vm.gH/2}}),_vm._l((_vm.bars),function(b,i){return _c('rect',{key:i,style:({fill: _vm.colors[b.sitetype]}),attrs:{"x":b.x,"y":b.y,"width":b.w,"height":b.h}})}),_c('g',{staticClass:"annotation"},[_c('path',{style:({fill: "none", stroke: _vm.colors[_vm.sitetypes[0]]}),attrs:{"d":("M40 10 c0 0 -35 0 -35 " + (_vm.gH / 2 - 10))}}),_c('path',{style:({fill: "none", stroke: _vm.colors[_vm.sitetypes[1]]}),attrs:{"d":("M40 " + (_vm.gH - 10) + " c0 0 -35 0 -35 " + (-1 * (_vm.gH / 2 - 10)))}}),_c('text',{style:({
          'font-size': '12px',
          'font-family': 'Clear Sans',
          'dominant-baseline': 'middle',
          'fill': _vm.colors[_vm.sitetypes[0]],
          }),attrs:{"x":"40","y":"10"}},[_vm._v(_vm._s(_vm.types[_vm.sitetypes[0]])+" ")]),_c('text',{style:({
          "font-size": "12px",
          "font-family": "Clear Sans",
          "dominant-baseline": "middle",
          fill: _vm.colors[_vm.sitetypes[1]],
          }),attrs:{"x":"40","y":_vm.gH - 10}},[_vm._v(_vm._s(_vm.types[_vm.sitetypes[1]])+" ")]),_c('text',{style:({
          "font-size": "12px", "font-family": "Clear Sans", "font-style": "italic", "dominant-baseline": "end"
        }),attrs:{"x":"0","y":_vm.gH + 16}},[_vm._v(" Selected dates: "+_vm._s(_vm.fromF)+" — "+_vm._s(_vm.toF)+" ")])])],2),_c('text',{style:({"font-size": _vm.slug === "external-rule" ? "20px" : "24px", "font-family": "Clear Sans", "font-weight": "bold"}),attrs:{"x":"75","y":"69"}},[_vm._v(_vm._s(_vm.title)+" ")]),_c('text',{style:({"font-size": "14px", "font-family": "Clear Sans"}),attrs:{"x":"75","y":"98"}},[_vm._v("% of news on topic as a share of total in a group of sites ")])])]),_c('canvas',{ref:"canvas",attrs:{"width":_vm.svgW*1.7,"height":_vm.svgH*1.7}}),_c('div',{attrs:{"id":"png-container"}},[_c('img',{attrs:{"src":_vm.png}}),_c('a',{ref:"download",attrs:{"href":_vm.png,"download":("texty_topic_radar_" + _vm.slug + ".png"),"target":"blank_"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }