<template>
<transition name="dropdown">
  <div id="disclaimer" v-if="show">
    <p><label id="hide-disclaimer" for="hide-disclaimer-icon">
      <input type="checkbox" id="hide-disclaimer-icon" v-model="show">
      <svg viewBox="-1 -1 21 21">
        <path d="M0 0 L20 20 M0 20 L 20 0" vector-effect="non-scaling-stroke"/>
      </svg>
    </label></p>
    <p>The visualization shows an overall dynamics of topics of disinformation in news from a sample of sites. Manipulative news and topics were determined by machine learning. Therefore, the data is not completely accurate but reflects a general trend.</p>
    <p>Topic names reflect manipulations used in the topic. Accordingly, <strong>news stories on Ukrainian mainstream sites on the same topic may have completely different content from that of manipulative materials</strong>.</p>
    <p>Please, find more in the <a to="#methodology" class="link" role="button">methodology</a>.</p>
  </div>
</transition>
</template>

<script>
export default {
  name: 'Disclaimer',
  data() {
    return {
      show: true,
      listener: function() {
        const methodology = document.getElementById('methodology');
        if (methodology.getBoundingClientRect().y - window.innerHeight * 0.33 < 0) {
          document.querySelector('#hide-disclaimer').click();
        }
      },
    }
  },

  watch: {
    show: function() {
      window.removeEventListener('scroll', this.listener);
      this.$destroy;
    }
  },

  mounted() {
    window.addEventListener('scroll', this.listener);
  },
}
</script>
