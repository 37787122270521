<template>

<main>

  <div class="header-side">
    <p>Select dates</p>
  </div>
  <header-time :days="days"/>
  <div class="header-side"></div>

  <sitetype/>

  <p class="legend">The bar height shows % of the topic among all the news of the day in a group of sites</p>

  <article id="topic-view">
    <div id="loading" v-if="loadingTopics">
      <img src="img/loading_icon.png"/>
    </div>
    <router-link v-for="(v, k, i) in topics" :key="i"
      :to="{path: '/' + k, query: $route.query}"
      tag="div"
      class="metatopic-chart"
    >
      <h3 class="h-metatopic">{{ meta2name[k] }}</h3>
      <figure>
        <chart :daily="v"
          :slug="k"
          :days="days"
          :i="i"
          :annotate="i === 0"
          :maxY="maxY"
        />
      </figure>
    </router-link>
  </article>
  
<slot></slot>
</main>

</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Home',
  data() {
    return {
      topics: {},
      publicPath: process.env.BASE_URL,
      loadingTopics: true,
    }
  },
  props: {
    days: Array,
    meta2name: Object,
  },

  created() {
    this.loadData(this.sitetypes)
  },

  mounded() {
    document.querySelectorAll('a[to]')
      .forEach(function(el) {
        el.addEventListener('click', function(e) {
          document.querySelector(e.target.getAttribute('to'))
            .scrollIntoView({behavior: 'smooth'});
        });
      });
  },
  computed: {
    sitetypes: function() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['uk_man', 'ru_for_uk_man'];
    },

    maxY: function() {
      const that = this;
      const m = d3.max(Object.values(that.topics), function(d) {
        return d3.max(d, v => v.perc)
      }) * 100;

      return Math.floor(m / 5) / 20;
    }
  },
  methods: {
    loadData (st) {
      const that = this;
      Promise.all(
        [...st.map(n => d3.json(`${that.publicPath}data/${n}/daily_counts.json`))]
      ).then(function(files) {
        that.topics = files[0];
        if (files.length === 2) {
          Object.entries(files[1]).map(function([k, v]) {
            that.topics[k] = that.topics[k] ? that.topics[k].concat(v) : v
          });
        }
        that.loadingTopics = false;
      });
    },

    checkStUpd() {
      this.topics = {};
      this.loadingTopics = true;
      this.loadData(this.sitetypes);
    },
  },
  watch: {
    '$route.query.sitetypes': 'checkStUpd'
  },
}
</script>

<style lang="scss">
@import './assets/scss/home.scss';
</style>
