<template>
  <svg ref="svg" :width="svgW" :height="svgH" :class="{sized: graphsSized, chart: true}">
    <g v-if="axisComp" class="y-axis">
      <line x1="-7" x2="-7" y1="0" :y2="svgH" stroke-width="0.5px"/>
      <line x1="-10" x2="-4" :y1="svgH/2" :y2="svgH/2"/>
      
      <text x="-13" :y="svgH/2" class="lab-zero">0</text>
      <text x="-13" y="0" class="lab-top">
        {{ scaleYLab }}
      </text>
      <text x="-13" :y="svgH" class="lab-bottom">
        {{ scaleYLab }}
      </text>
    </g>

    <g v-if="annotate" class="annotation">
      <path :d="`M40 2 c0 0 -25 0 -25 ${svgH / 2 - 5}`"
        v-if="!tipX"
        :style="{stroke: colors[sitetypes[0]]}"/>
      <path :d="`M40 ${svgH - 2} c0 0 -25 0 -25 ${-1 * (svgH / 2 - 5)}`"
        v-if="!tipX"
        :style="{stroke: colors[sitetypes[1]]}"/>
      <text x="40" y="2" class="label"
        :style="{fill: colors[sitetypes[0]]}">
        {{ types[sitetypes[0]] }}
        </text>
      <text x="40" :y="svgH -2" class="label"
        :style="{fill: colors[sitetypes[1]]}">
        {{ types[sitetypes[1]] }}
      </text>

      <text x="0" :y="svgH + 20" class="axis-lab">Selected dates: {{ fromF }} — {{ toF }} </text>
    </g>

    <line v-if="tipX && tipTop" class="tip-line" vector-effect="non-scaling-stroke"
      :x1="tipX" :x2="tipX" y1="-5" :y2="svgH/2"
      :style="{stroke: colors[sitetypes[0]]}"
    />
    <line v-if="tipX && tipBtm" class="tip-line" vector-effect="non-scaling-stroke"
      :x1="tipX" :x2="tipX" :y1="svgH + 5" :y2="svgH/2"
      :style="{stroke: colors[sitetypes[1]]}"
    />

    <line x1="0" :x2="svgW" :y1="svgH/2" :y2="svgH/2" class="zero-line"/>
    <rect v-for="(b, i) in bars" :key="i"
      :x="b.x"
      :y="b.y"
      :width="b.w"
      :height="b.h"
      :class="b.sitetype"
      :style="{fill: colors[b.sitetype]}"
    />
    <rect x="0" y="0"
      :height="svgH"
      :width="svgW"
      opacity="0"
      v-if="tooltip"
      :name="slug"
      @mousemove="showTip"
      @mouseout="tipX = null"
      @click="scrollHeadlines"
    />

    <tippy v-if="tooltip && graphsSized" delay="500" :to="slug" followCursor="horizontal" trigger="mouseenter" distance="-16" placement="top" sticky="false" flip="false" hideOnClick="false" multiple
      :content="tipTop"
    />
    <tippy v-if="tooltip && graphsSized" delay="500" :to="slug" followCursor="horizontal" trigger="mouseenter" distance="-16" placement="bottom" sticky="false" flip="false" hideOnClick="false" multiple
      :content="tipBtm"
    />
  </svg>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Chart',
  props: {
    daily: Array,
    slug: String,
    days: Array,
    axis: {default: false, type: Boolean},
    i: {default: null, type: Number},
    annotate: {default: false, type: Boolean},
    tooltip: {default: false, type: Boolean},
    scaled: {default: false, type: Boolean},
    maxY: {default: 0.5, type: Number},
  },
  data() {
    return {
      svgW: 0,
      svgH: 0,
      hoverRect: null,
      tipX: null,
      tipTop: null,
      tipBtm: null,
      graphsSized: false,
      types: {
        uk_man: 'Ukrainian manipulative',
        ru_for_uk_man: 'Russian sites targeting Ukraine',
        reg: 'Local Ukrainian sites, all news',
        uk: 'Major Ukrainian sites, all news',
        ru: 'Major Russian sites, all news',   
	tg: 'Telegram channels popular in Ukraine, all publications',

      },
      colors: {
        uk_man: '#ff007d',
        ru_for_uk_man: '#8c0061',
        reg: '#f967c8',
        uk: '#000000',
        ru: '#7d7d7d',
	tg: '#8d8d8d'
      }
    }
  },

  mounted() {
    const that = this;
    this.setSvgDims();
    window.addEventListener('resize', that.setSvgDims);
  },

  computed: {
    sitetypes: function() {
      const sts = this.$route.query.sitetypes;
      return sts ? sts.split(',') : ['uk_man', 'ru_for_uk_man'];
    },
    
    from: function() {
      const qFrom = this.$route.query.from;
      return qFrom ? new Date(qFrom) : this.pdate(this.days[this.days.length - 151])
    },

    to: function() {
      const qTo = this.$route.query.to;
      return qTo ? new Date(qTo) : this.pdate(this.days[this.days.length - 1])
    },

    filteredDaily: function() {
      const that = this;
      return that.daily
        .filter(d => new Date(d.date) >= that.from && new Date(d.date) <= that.to)
    },

    toF: function() {
      return this.fdate(this.to);
    },

    fromF: function() {
      return this.fdate(this.from);
    },

    selectedDays: function() {
      const that = this;
      return that.days
        .map(that.pdate)
        .filter(d => d >= that.from && d <= that.to)
        .sort((a, b) => a < b ? -1 : 1)
        .map(that.fdate)
    },

    scaleX: function() {
      const that = this;

      return d3.scaleBand()
        .domain(that.selectedDays)
        .range([0, that.svgW])
        .paddingInner(0.15);
    },

    scaleY: function() {
      const that = this;
      return d3.scaleLinear()
        .domain([0, that.maxY])
        .range([0, that.svgH / 2]);
    },

    scaleYLab: function() {
      const ymax = this.scaleY.domain()[1];
      return (ymax > 0 && ymax < 100) ? `${ymax * 100}%` : '…'
    },

    bars: function() {
      const that = this;

      return that.filteredDaily
        .map(function(d) {
          const y_abs = that.scaleY(d.perc);
          return {
            x: that.scaleX(that.fdate(new Date(d.date))),
            y: that.sitetypes.indexOf(d.sitetype) ? that.svgH / 2 : that.svgH / 2 - y_abs,
            h: y_abs,
            w: that.scaleX.bandwidth(),
            n_day: d.n,
            sitetype: d.sitetype,
            sitetype_r: that.types[d.sitetype],
          }
      })
    },

    axisComp: function() {
      if (this.axis) return true;
      if (this.i == null) return false;
      const w = window.innerWidth;
      if (w < 768) return true;
      if (w >= 992  && this.i % 3 === 0) return true
      if (w >= 768  && w < 992 && this.i % 2 === 0) return true
      return false;
    }
  },

  methods: {
    fdate(dt) {
      return d3.timeFormat('%d.%m.%y')(dt)
    },
    pdate(s) {
      return d3.timeParse('%d.%m.%y')(s)
    },
    setSvgDims() {
      const svg = this.$refs.svg;
      const coef = this.graphsSized && this.scaled
        ? window.innerWidth < 768 ? 2 : 1.7
        : 1;

      this.svgW = (svg.offsetWidth || svg.getBoundingClientRect().width || svg.clientWidth) / coef;
      this.svgH = (svg.offsetHeight || svg.getBoundingClientRect().height || svg.clientHeight) / coef;
      this.graphsSized = true;
    },

    showTip(event) {
      const that = this;
      const bcr = that.$el.getBoundingClientRect();
      const[w, left] = [bcr.width, bcr.x];
      const coef = that.scaled ? 1.7 : 1;
      const x = (event.x - left);
      let day = Math.round(x / w * that.selectedDays.length);
      day = that.selectedDays[day];
      const selDay = that.daily.filter(d => that.fdate(new Date(d.date)) === day);
      const topD = selDay.filter(d => d.sitetype === that.sitetypes[0]);
      const btmD = selDay.filter(d => d.sitetype === that.sitetypes[1])
      that.tipX = x / coef;
      const st1 = that.sitetypes[0]
      that.tipTop = topD.length === 0
        ? null
        : `
        <div class="tip tip-top">
          <h6>${that.fdate(new Date(topD[0].date))} — <span style="color: ${that.colors[st1]}">${that.types[st1]}</span></h6>
          <p>% of news on topic as a share of total in a group of sites: ${Math.round(topD[0].perc * 1000) / 10 + '%'}</p>
          <p>Number of items: ${topD[0].n}</p>
        </div>
        `;
        const st2 = that.sitetypes[1]
        that.tipBtm = btmD.length === 0
        ? null
        : `
        <div class="tip tip-btm">
          <h6>${that.fdate(new Date(btmD[0].date))} — <span style="color: ${that.colors[st2]}">${that.types[st2]}</span></h6>
          <p>% of news on topic as a share of total in a group of sites: ${Math.round(btmD[0].perc * 1000) / 10 + '%'}</p>
          <p>Number of items: ${btmD[0].n}</p>
        </div>
        `
    },

    scrollHeadlines(event) {
      const that = this;
      const bcr = that.$el.getBoundingClientRect();
      const[w, left] = [bcr.width, bcr.x];
      const x = (event.x - left);
      let day = Math.round(x / w * that.selectedDays.length);
      day = that.selectedDays[day];
      that.$emit('scroll-headlines', day)
    }
  }
}
</script>


